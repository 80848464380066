import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';
//import 'font-awesome/css/font-awesome.min.css';
//import '@fortawesome/fontawesome-free/css/all.css';
//import '@fortawesome/fontawesome-free/js/all.js';
import 'superfish/dist/css/superfish.css';
import superfish from 'superfish';
//import 'mmenu-js/dist/mmenu.css';
import mmenu from 'mmenu-js';
import mmenupolyfills from 'mmenu-js';
import micromodal from 'micromodal';
require("jquery-ui/ui/widgets/accordion");

//console.log(accordion);

jQuery(document).ready(function() {

    seafarer_enable_mobile_menu();

    seafarer_resize_elements();

    seafarer_side_menu();

    if( jQuery('.page-slider').length ) {

        // jQuery('.page-slider').on('init', (slick) => {
        //     let headerColor = jQuery('.slick-slide.slick-current').find('.slide').data('color');
        //     seafarer_set_header_color( headerColor );
        // });

        jQuery('.page-slider').each(function(i, slider){

            jQuery(this).slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                fade: true,
                speed: 2000,autoplay: true,
                autoplaySpeed: 5000
            });

        });

        // jQuery('.page-slider').on('afterChange', (slick, currentSlide) => {
        //     let headerColor = jQuery('.slick-slide.slick-current').find('.slide').data('color');
        //     seafarer_set_header_color( headerColor );
        // });

    }

    jQuery('#main-menu').superfish({
        delay: 0,
        cssArrows: false
    });

    jQuery('#search').on('click', function(){
        jQuery(this).find('#search-form').fadeIn().find('input').focus();
    });

    jQuery('.works-table').each(function(i, tab){

        if( ! jQuery(this).hasClass('no-resize') ) {
            var width = 0;
            jQuery(this).find('.col').each(function(i, col){
                if( jQuery(this).width() > width ) {
                    width = jQuery(this).width();
                }
            });
            jQuery(this).find('.col').css({ width: width + 'px' });
        }

    });

    jQuery('.works-menu li a').on('click', function() {

        jQuery('.works-menu li').removeClass('active');
        jQuery(this).parent().toggleClass('active');

        var works = jQuery(this).data('works');

        jQuery('.works-table').fadeOut();
        jQuery('.works-table[data-works="' + works + '"]').fadeIn();

        if( jQuery('.work-detail').length ) {
            jQuery('.work-detail').hide();
            jQuery('.work-detail[data-work="' + works + '"]').fadeIn();
        }

    });

    if( jQuery('.open-reviews').length ) {

        jQuery('.open-reviews').on('click', function(){
            jQuery('.inner-content.single-work-reviews').show();
            jQuery('.reviews').slideDown();
            jQuery(this).fadeOut();
        });

        jQuery('.close-reviews').on('click', function(){
            jQuery('.reviews').slideUp();
            jQuery('.inner-content.single-work-reviews').hide();
            jQuery('.open-reviews').fadeIn();
        });

    }

    if( jQuery('.single-work-product-header').length ) {

        var contentTrigger = jQuery('.single-work-product-header');

        contentTrigger.on('click', function() {

            var productId = jQuery(this).data('prodid'),
                productBody = jQuery('#single-work-product-body-' + productId),
                productVoicings = jQuery(this).data('voicings')
                productVoicings = productVoicings ? productVoicings.split(',') : null;

            if( jQuery(this).hasClass('active') ) {

                jQuery(this).removeClass('active');
                productBody.slideUp();

            } else {

                jQuery('.single-work-product-header').removeClass('active');
                jQuery(this).addClass('active');

                if( ! productBody.html() ) {

                    jQuery.ajax('/wp-json/seafarer/v1/gpdm', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('X-WP-Nonce', seafarer.nonce);
                        },
                        data: JSON.stringify( {
                            productId: productId
                        } ),
                        dataType: 'json',
                        success: function( content ) {

                            jQuery('.single-work-product-body').hide();
                            productBody.html( content.markup ).slideDown();

                        }
                    });

                } else {
                    jQuery('.single-work-product-body').hide();
                    productBody.slideDown();
                }

            }

        });

    }

    if( jQuery('.products-menu').length ) {

        var trigger = jQuery('.products-menu li');

        trigger.on('click', function(){

            var container = jQuery('work-detail'),
                voicing = jQuery(this).data('voicing'),
                workId = jQuery(this).data('workid');

            jQuery.ajax('/wp-json/seafarer/v1/gpds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-WP-Nonce', seafarer.nonce);
                },
                data: JSON.stringify( {
                    voicing: voicing,
                    workId: workId
                } ),
                dataType: 'json',
                success: function( products ) {

                    jQuery('.work-detail').html( products.markup ).show();

                }
            });
        });

    }

    if( jQuery('.alternative-navigation-header').length ) {

        var contentTrigger = jQuery('.alternative-navigation-header');

        contentTrigger.on('click', function() {

            var tax     = jQuery(this).data('tax'),
                taxBody = jQuery('#alternative-navigation-body-' + tax);

            if( jQuery(this).hasClass('active') ) {

                jQuery(this).removeClass('active');
                taxBody.slideUp();

            } else {

                jQuery('.alternative-navigation-header').removeClass('active');
                jQuery(this).addClass('active');

                taxBody.slideDown();

            }

        });

    }

    if( jQuery('.single-work-video-slider').length ) {

        jQuery('.single-work-video-slider').slick({
            adaptiveHeight: true,
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""></button>',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Previous" type="button" style=""></button>'
        });

    }

    jQuery('body').on('click', '.works-menu-sec a', function(){
        jQuery(this).toggleClass('active-selection');
        jQuery('.works-menu').slideToggle();
    });

    jQuery('body').on('click', '.works-menu a', function(){
        jQuery('.works-menu-sec a').text( jQuery(this).text() );
        if( jQuery(window).width() < 1024 ) {
            jQuery('.works-menu').slideUp();
        }
    });

    if( jQuery('.order-form-product-qty').length ) {

        jQuery('body').on( 'change', '.order-form-product-qty', function(){

            if( jQuery(this).val() > 0 ) {
                jQuery(this).parent().parent().addClass('active');
            } else {
                jQuery(this).parent().parent().removeClass('active');
            }

            var prodId = jQuery(this).data('pid'),
                qty    = jQuery(this).val();

            if( qty > 0 ) {

                if( ! jQuery('#seafarer-review-order .works-products .ro-row[data-pid="' + prodId + '"]').length ) {

                    var row     = jQuery('.works-table .row[data-pid="' + prodId + '"]'),
                        titleVoicing = row.find('.title-voicing-score_type').html(),
                        sku     = row.find('.sku').text(),
                        type    = row.find('.type').text(),
                        price   = row.find('.price').text();

                    var markup = '<div class="ro-row" data-pid="' + prodId + '">';
                        markup += '<div class="ro-col title-voicing">' + titleVoicing + '</div>';
                        markup += '<div class="ro-col sku">' + sku + '</div>';
                        markup += '<div class="ro-col type">' + type + '</div>';
                        markup += '<div class="ro-col price">' + price + '</div>';
                        markup += '<div class="ro-col qty">' + qty + '</div>';
                        markup += '</div>';

                    jQuery('#seafarer-review-order .works-products').append(markup);

                } else {

                    jQuery('#seafarer-review-order .works-products .ro-row[data-pid="' + prodId + '"]').find('.ro-col.qty').text( qty );

                }

                calculate_order_total();

            } else {

                jQuery('#seafarer-review-order .works-products .ro-row[data-pid="' + prodId + '"]').remove();

            }

        });

    }

    jQuery('body').on( 'change', 'input[name="order-name"], input[name="order-email"], input[name="order-phone"], input[name="order-mailing-address"], textarea[name="order-comments"]', function(){

        var field = jQuery(this).attr('name');

        jQuery('#seafarer-review-order .personal-details .ro-col.' + field).text( jQuery(this).val() );

    });

    if( jQuery('#seafarer-order-shipping').length ) {

        if( ! jQuery('#seafarer-order-shipping').hasClass('active') ) {
            jQuery('#ship-add-same-as-bill input[type="checkbox"]').prop('checked', 1);
        }

    }

    jQuery('body').on('change', '#ship-add-same-as-bill input[type="checkbox"]', function(){

        jQuery('#seafarer-order-shipping').toggle();
        jQuery('#seafarer-order-shipping').toggleClass('active');

        if( jQuery(this).is(':checked') ) {
            jQuery('#seafarer-review-order .user-shipping-same-billing').show();
            jQuery('#seafarer-review-order .user-shipping-address-fields').hide();
        } else {
            jQuery('#seafarer-review-order .user-shipping-same-billing').hide();
            jQuery('#seafarer-review-order .user-shipping-address-fields').show();
        }

    });

    jQuery('body').on('click', '#seafarer-review-place-order', function(e){

        e.preventDefault();

        var errors = 0;

        var hasQtyField = jQuery('.order-form-product-qty').filter(function() {
            return parseInt( jQuery(this).val(), 10 ) > 0;
        });

        if( ! hasQtyField.length ) {
            alert( 'You need to select at least one product from the list.' );
            errors = 1;
            jQuery('html, body').animate({ scrollTop: 0 }, 1500);
            return false;
        } else {
            errors = 0;
        }

        var fields = [
            jQuery('input[name="order-email"]'),
            jQuery('input[name="order-billing-name"]'),
            jQuery('select[name="order-billing-country"]'),
            jQuery('input[name="order-billing-address1"]'),
            jQuery('input[name="order-billing-city"]'),
            jQuery('select[name="order-billing-state"]'),
            jQuery('input[name="order-billing-zip"]'),
            jQuery('input[name="order-phone"]'),
        ];

        if( ! jQuery('input[name="ship-add-same-as-bill[]"]').is(':checked') ) {
            fields.push( jQuery('input[name="order-shipping-name"]') );
            fields.push( jQuery('select[name="order-shipping-country"]') );
            fields.push( jQuery('input[name="order-shipping-address1"]') );
            fields.push( jQuery('input[name="order-shipping-city"]') );
            fields.push( jQuery('select[name="order-shipping-state"]') );
            fields.push( jQuery('input[name="order-shipping-zip"]') );
        }

        //var fields = [ jQuery('.wpcf7-validates-as-required') ];

        jQuery.each( fields, function( i, field ) {

            if( ! field.val() ) {
                field.css('border', '1px solid red');
                jQuery('html, body').animate({
                    scrollTop: field.offset().top - 20
                }, 1000);
                field.focus();
                errors = 1;
                return false;
            } else {
                field.css('border', '1px solid #526681');
                errors = 0;
            }

            if( field.hasClass('wpcf7-validates-as-email') ) {

                var emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;

                if( emailReg.test( field.val() ) == false ) {
                    field.css('border', '1px solid red');
                    jQuery('html, body').animate({
                        scrollTop: field.offset().top - 20
                    }, 1000);
                    field.focus();
                    errors = 1;
                    return false;
                } else {
                    field.css('border', '1px solid #526681');
                    errors = 0;
                }

            }

        });

        if( ! errors ) {

            jQuery('.works-table, .seafarer-order-form-fields, .seafarer-order-form-comments, #seafarer-review-place-order').hide();
            jQuery('#content .wp-block-group, h1.page-title-below-fi').hide();
            jQuery('#seafarer-review-order, .seafarer-submit-order').show();
            jQuery('html, body').animate({ scrollTop: 0 }, 1000);

        }

    });

    jQuery('body').on('click', '.change-order-details', function(){

        jQuery('.works-table, .seafarer-order-form-fields, .seafarer-order-form-comments, #seafarer-review-place-order').show();
        jQuery('#content .wp-block-group, h1.page-title-below-fi').show();
        jQuery('#seafarer-review-order, .seafarer-submit-order').hide();

        if( jQuery('#ship-add-same-as-bill input[type="checkbox"]').is(':checked') ) {
            jQuery('#seafarer-order-shipping').hide();
        } else {
            jQuery('#seafarer-order-shipping').show();
        }

    });

    if( jQuery('.seafarer-accordion').length ) {
        jQuery('.seafarer-accordion').each(function(i, acc){
            jQuery(this).accordion({
                heightStyle: 'content',
                collapsible: true,
                active: false,
                icons: false
            });
        });
    }

    jQuery( 'body' ).on('click', '.sort-works', function(e) {

        let elem = jQuery(this),
            genre = jQuery(this).parent().parent().parent().data('works'),
            tax = jQuery(this).parent().parent().parent().data('tax'),
            term = jQuery(this).parent().parent().parent().data('term'),
            order = elem.data('order'),
            orderBy = elem.data('orderby');

        console.log(order, orderBy);

        jQuery.ajax('/wp-json/seafarer/v1/sw', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', seafarer.nonce);
            },
            data: JSON.stringify( {
                order: order,
                orderBy: orderBy,
                genre: genre,
                tax: tax,
                term: term
            } ),
            dataType: 'json',
            success: function( content ) {

                console.log(content);

                elem.data( 'order', content.nextorder );
                jQuery('.works-table[data-works="' + content.genre + '"] .sort-works .arrow' ).remove();
                elem.append( ' <i class="arrow ' + content.arrow + '"></i>' );
                jQuery('.works-table[data-works="' + content.genre + '"] .works-table-container' ).empty().html( content.payload );

            }
        });

    });

    jQuery( 'body' ).on('click', '.sort-products-order-form', function(e){

        let elem = jQuery(this),
            order = elem.data('order'),
            orderBy = elem.data('orderby'),
            qty = [];

        jQuery('.order-form-product-qty').each(function(){
            qty.push(
                jQuery(this).data('pid') + '|' + jQuery(this).val()
            );
        });

        //console.log(order, orderBy);

        jQuery.ajax('/wp-json/seafarer/v1/spof', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', seafarer.nonce);
            },
            data: JSON.stringify( {
                order: order,
                orderby: orderBy,
                qty: qty
            } ),
            dataType: 'json',
            success: function( content ) {

                //console.log(content);

                elem.data( 'order', content.nextorder );
                jQuery('.works-table .sort-products-order-form .arrow' ).remove();
                elem.append( ' <i class="arrow ' + content.arrow + '"></i>' );
                jQuery('.works-table .works-table-container' ).empty().html( content.payload );

            }
        });

    });

    jQuery('#search .search-icon').on( 'click', function(){
        jQuery('#search #search-form').fadeToggle();
        jQuery('#search #search-form input[name="s"]').focus();
    });

    jQuery('#main-menu-trigger').on('mouseover', function(){
        jQuery('#main-menu-side').addClass('active');
        jQuery('#main-menu-overlay').addClass('active');
    });

    jQuery('#main-menu-side').on('mouseleave', function(){
        jQuery('#main-menu-side').removeClass('active');
        jQuery('#main-menu-overlay').removeClass('active');
    });

    jQuery('.page-template-template-videos .wp-block-columns').each( function(){

        let height = 0,
            paraphs = jQuery(this).find( '.wp-block-column p' );

        paraphs.each(function(){
            if( jQuery(this).height() > height ) {
                height = jQuery(this).height();
            }
        });

        paraphs.css( 'height', height + 'px' );

    });

    seafarer_order_form_handle();

    if( jQuery('a[href^="#"]').length ) {

        jQuery(document).on('click', 'a[href^="#"]', function(e) {
            e.preventDefault();
            jQuery('html, body').animate({
                scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top
            }, 1000);
        });

    }

    if( jQuery('.block-video').length && jQuery('.block-video-modal').length ) {

        jQuery('.block-video-load-modal').on('click', function(){

            let modalId = jQuery(this).data('modalid'),
                videoId = jQuery(this).data('videoid');

            MicroModal.show('block-video-modal-' + modalId, {
                onClose: function( modal ) {
                    jQuery('#block-video-modal-content .modal-video-content').html( '<div class="modal-preloader"></div>' );
                }
            });

            jQuery.ajax('/wp-json/seafarer/v1/gvbi', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-WP-Nonce', seafarer.nonce);
                },
                data: JSON.stringify( {
                    videoId: videoId
                } ),
                dataType: 'json',
                success: function( content ) {

                    jQuery('#block-video-modal-' + modalId + ' .modal-title').text( content.title );
                    jQuery('#block-video-modal-' + modalId + ' .modal-content .modal-video-content').html( content.video );

                }
            });

        });

    }

});

jQuery(window).on('resize', function() {
    seafarer_enable_mobile_menu();
    seafarer_resize_elements();
});

jQuery(window).scroll(function() {

    if( jQuery('main#inner aside ul.works-menu').length ) {

        let stickySidebar = jQuery('main#inner aside ul.works-menu').offset().top;

        if( jQuery(window).scrollTop() > stickySidebar ) {
            jQuery('main#inner aside ul.works-menu').addClass('sticky');
        } else {
            jQuery('main#inner aside ul.works-menu').removeClass('sticky');
        }

        let bottom = jQuery('.bottom').offset().top,
            windowBottom = jQuery(window).scrollTop() + jQuery(window).height();

        if( windowBottom > bottom ) {
            jQuery('main#inner aside ul.works-menu').removeClass('sticky');
        }

    }

    if( jQuery('header').hasClass( 'no-bg' ) ) {

        let target = function() {
            if( jQuery('.page-slider').length ) {
                return jQuery('.page-slider');
            } else if( jQuery('#featured-image').length ) {
                return jQuery('#featured-image');
            } else {
                return 'no-bg';
            }
        }

        let headerStyles = false;

        if( target() != 'no-bg' ) {

            headerStyles = target()[0].getBoundingClientRect().bottom < 0 ? true : false;

        } else {

            headerStyles = jQuery(window).scrollTop() >= 20 ? true : false;

        }

        if( headerStyles == true ) {
            jQuery('header').addClass('below-hero');
            jQuery('header #main-menu-trigger svg g#Topnav---white-bg').attr('fill', '#a2a2a2');
        } else {
            jQuery('header').removeClass('below-hero');
            jQuery('header#main-menu-trigger svg g#Topnav---white-bg').attr('fill', '#fff');
        }

    }

    if( sfcustom.sh ) {

        let scroll = jQuery(window).scrollTop();

        if (scroll >= 50) {
            jQuery('header').addClass('sticky');
        } else {
            jQuery('header').removeClass('sticky');
        }

    }

});

document.addEventListener( 'wpcf7mailsent', function( event ) {

    if( jQuery('.seafarer-order-form-fields').length && sfcustom.iop && sfcustom.ocp ) {
        setTimeout(function(){
            //location.reload(true);
            window.location.href = sfcustom.ocp;
        }, 2500);
    }

}, false );

function seafarer_resize_elements() {

    jQuery('.bottom').css({ height: jQuery(window).height() + 'px' });
    jQuery('.page-slider .slide').css({ height: jQuery(window).height() + 'px' });

}

function seafarer_set_header_color( color ) {

    jQuery('.page-slider .slide .home-slider-caption').css({ color: color });

}

function seafarer_enable_mobile_menu() {

    if( jQuery(window).width() < 1024 && ! jQuery('.mm-page').length ) {

        //jQuery('#mobile-main-menu').show();

        var menuTopContent = '<div class="nav-bar-inner">';
            menuTopContent += '<div class="nav-bar-left">';
                //menuTopContent += '<img src="#!" alt="nav-logo" />';
            menuTopContent += '</div>';
            menuTopContent += '<div class="nav-bar-right">';
                menuTopContent += '<button id="mobile-nav-close" class="menu-close">';
                    menuTopContent += '<span class="menu-close-bar"></span><span class="menu-close-bar"></span>';
                menuTopContent += '</button>';
            menuTopContent += '</div>';
        menuTopContent += '</div>';

        var menu = new Mmenu( '#mobile-main-menu nav', {
            navbars: [{
                content: menuTopContent
            }]
        } );

        var api = menu.API;

        jQuery('body').on('click', '#mobile-nav-toggle', function(e){
            e.preventDefault();
            api.open();
            jQuery(this).addClass('active');
            jQuery(this).attr('id', 'mobile-nav-close');
        });

        jQuery('body').on('click', '#mobile-nav-close', function(e){
            e.preventDefault();
            api.close();
            jQuery(this).removeClass('active');
            jQuery(this).attr('id', 'mobile-nav-toggle');
        });

    } else {
        jQuery('#mobile-main-menu').hide();
    }

}

function seafarer_side_menu() {

    jQuery('#main-menu-side nav ul li.menu-item-has-children').each( function(){

        jQuery(this).append( '<span class="expand-menu retract"></span>' );

    });

    jQuery('#main-menu-side nav ul li.menu-item-has-children > a').on( 'click', function(e){
        e.preventDefault();
        jQuery(this).parent().find('ul.sub-menu').slideToggle();
        jQuery(this).parent().find('span.expand-menu').toggleClass('expand');
    });

}

function calculate_order_total() {

    var orderTotal = 0;

    jQuery('.works-products .ro-row').each(function(i, prod){

        var unitPrice = jQuery(this).find('.ro-col.price').text().replace('$', ''),
            qty       = jQuery(this).find('.ro-col.qty').text(),
            rowTotal  = parseFloat( unitPrice ) * parseInt( qty );

        //console.log(unitPrice, qty, rowTotal);

        if( unitPrice && qty && rowTotal ) {
            orderTotal += rowTotal;
        }

    });

    if( orderTotal ) {
        jQuery('.order-total').text( 'Order total: $' + orderTotal );
    }

}

function seafarer_order_form_handle() {

    jQuery('.order-review-billing-country').text( jQuery('input[name="order-billing-country"]').val() );
    jQuery('.order-review-billing-state').text( jQuery('input[name="order-billing-state"]').val() );
    jQuery('.order-review-shipping-country').text( jQuery('input[name="order-shipping-country"]').val() );
    jQuery('.order-review-shipping-state').text( jQuery('input[name="order-shipping-state"]').val() );

    let fields = [
        { field: jQuery('input[name="order-email"]'), target: jQuery('.order-review-email') },
        { field: jQuery('input[name="order-billing-name"]'), target: jQuery('.order-review-billing-name') },
        { field: jQuery('input[name="order-billing-company"]'), target: jQuery('.order-review-billing-company') },
        { field: jQuery('select[name="order-billing-country"]'), target: jQuery('.order-review-billing-country') },
        { field: jQuery('input[name="order-billing-address1"]'), target: jQuery('.order-review-billing-address1') },
        { field: jQuery('input[name="order-billing-address2"]'), target: jQuery('.order-review-billing-address2') },
        { field: jQuery('input[name="order-billing-city"]'), target: jQuery('.order-review-billing-city') },
        { field: jQuery('select[name="order-billing-state"]'), target: jQuery('.order-review-billing-state') },
        { field: jQuery('input[name="order-billing-zip"]'), target: jQuery('.order-review-billing-zip') },
        { field: jQuery('input[name="order-phone"]'), target: jQuery('.order-review-phone') },
        { field: jQuery('input[name="order-mn-tax-exempt"]'), target: jQuery('.order-review-mn-tax-exempt') },
        { field: jQuery('input[name="order-pdf-only-name"]'), target: jQuery('.order-review-pdf-only-name') },
        { field: jQuery('input[name="order-delivery-date"]'), target: jQuery('.order-review-delivery-date') },
        { field: jQuery('textarea[name="order-comments"]'), target: jQuery('.order-review-comments') },
        { field: jQuery('input[name="order-shipping-name"]'), target: jQuery('.order-review-shipping-name') },
        { field: jQuery('input[name="order-shipping-company"]'), target: jQuery('.order-review-shipping-company') },
        { field: jQuery('select[name="order-shipping-country"]'), target: jQuery('.order-review-shipping-country') },
        { field: jQuery('input[name="order-shipping-address1"]'), target: jQuery('.order-review-shipping-address1') },
        { field: jQuery('input[name="order-shipping-address2"]'), target: jQuery('.order-review-shipping-address2') },
        { field: jQuery('input[name="order-shipping-city"]'), target: jQuery('.order-review-shipping-city') },
        { field: jQuery('select[name="order-shipping-state"]'), target: jQuery('.order-review-shipping-state') },
        { field: jQuery('input[name="order-shipping-zip"]'), target: jQuery('.order-review-shipping-zip') }
    ];

    fields.map( function(field, i){

        field.field.on('change', function(){
            field.target.text( jQuery(this).val() );
        });

    });

}
